<template>
  <div>
    <b-row>
      <b-col md="6">
        <editor
            v-model="contract.value.contract_right"
            api-key="2o3k788xzr1fv0vflhktfehfjcuwq2rlyucs4yolxxvjbrss"
            :init="{
          selector: 'textarea',
         height: 500,
         menubar: false,
        directionality: 'rtl',
         plugins: [
           'advlist autolink lists link image charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help wordcount'
         ],
         toolbar:
           'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
       }"
        />
      </b-col>
      <b-col md="6">
        <editor
            v-model="contract.value.contract_left"
            api-key="2o3k788xzr1fv0vflhktfehfjcuwq2rlyucs4yolxxvjbrss"
            :init="{
          selector: 'textarea',
         height: 500,
         menubar: false,
        directionality: 'rtl',
         plugins: [
           'advlist autolink lists link image charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help wordcount'
         ],
         toolbar:
           'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
       }"
        />
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col md="12" class="d-flex justify-content-center">
        <b-button variant="primary" @click="savaContract" v-if="!loadingSubmit">
          Save Contract
        </b-button>
        <b-button variant="primary" @click="savaContract" v-else>
          <spinner-loading text="loading"/>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { core } from '@/config/pluginInit'
import memberShipServices from '@/services/MemberShip/memberShip'

export default {
  name: 'contractSetting',
  components: {
    editor: Editor
  },
  methods: {
    savaContract () {
      this.loadingSubmit = true
      if (this.contract.value.contract_left && this.contract.value.contract_right) {
        memberShipServices.saveContract(this.contract).then(res => {
          core.showSnackbar('success', res.data.message)
        }).finally(() => {
          this.loadingSubmit = false
        })
      } else {
        core.showSnackbar('error', 'you should write in right and left')
      }
    }
  },
  data () {
    return {
      loadingSubmit: false,
      contract: {
        value: {
          contract_left: '',
          contract_right: ''
        },
        is_active: 1
      }
    }
  }
}
</script>
